<template>
  <!-- 角色管理 -->
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <el-button style="margin-bottom: 10px" v-checkbtn="isShowBtn(AUTH_BTN.role_list_add)" type="primary" size="mini" plain icon="el-icon-plus" @click="handleAdd"
      >新增</el-button
    >
    <el-table :data="list">
      <el-table-column prop="content" label="角色名称" min-width="120"> </el-table-column>
      <el-table-column prop="content" label="描述" min-width="120"> </el-table-column>

      <el-table-column prop="createTime" label="创建时间" min-width="160"> </el-table-column>
      <el-table-column label="操作" width="120" fixed="right">
        <template slot-scope="{ row }">
          <el-link style="margin-right: 10px" type="primary" :underline="false" @click="showMenu(row['id'])">授权菜单</el-link>
          <el-link style="margin-right: 10px" type="primary" :underline="false" @click="handleEdit(row)">编辑</el-link>
          <el-link :underline="false" type="danger" @click="handleDel(row)">删除</el-link>
        </template>
      </el-table-column>
    </el-table>
    <pagination :total="total" :page.sync="searchData.page" :limit.sync="searchData.pagesize" @pagination="getList" />

    <!-- 授权 -->
    <el-drawer ref="drawer" title="角色权限" :visible.sync="drawer" :show-close="false">
      <el-card>
        <div slot="header">
          <el-button type="primary" size="small" @click="saveAuthority">保存设置</el-button>
        </div>
        <div>
          <el-tree
            ref="tree"
            highlight-current
            :data="menuTree"
            show-checkbox
            node-key="id"
            default-expand-all
            :default-checked-keys="grantMenuObj.menu_ids"
            :props="defaultProps"
            @check="checkChange"
          >
          </el-tree>
        </div>
      </el-card>
    </el-drawer>
  </div>
</template>

<script>
import { getRoleList, removeRole, getMenusTree, grantMenu, getRoleMenu, getRoleDetail, editRole } from './api'
import { authBtnMixin } from '@/mixins/authBtnMixin'

export default {
  name: 'Index',
  mixins: [authBtnMixin],
  data() {
    return {
      menuModule: 'admin',
      drawer: false,
      roleList: [],

      total: 0,
      currentPage: 1,
      pageSize: 20,
      defaultProps: {
        children: 'children',
        label: 'menu_name',
        value: 'id'
      },
      menuTree: [],
      // 授权信息
      grantMenuObj: {
        id: null,
        menu_ids: []
      },
      searchData: {
        roleName: ''
      },
      allKeys: [],
      detailInfo: {}
    }
  },
  provide() {
    return {
      context: this
    }
  },
  watch: {
    currentPage() {
      this.getRoleList()
    }
  },
  mounted() {
    // this.getRoleList()
  },

  methods: {
    async getRoleList() {
      let params = { roleName: this.searchData.roleName, page: this.currentPage, page_size: this.pageSize }
      let res = await getRoleList(params)
      this.roleList = res?.data || []
      this.total = res?.total || 0
    },

    async apiQueryMenuTree() {
      this.menuTree = await getMenusTree()
    },
    // 复选框被点击时的回调
    checkChange() {
      // 获取当前被选中的菜单id数组
      this.grantMenuObj.menu_ids = this.$refs.tree.getCheckedKeys()
      this.allKeys = this.$refs.tree.getCheckedNodes(false, true).map((item) => item.id)
    },
    // 保存菜单授权
    async saveAuthority() {
      // let data = { id: this.grantMenuObj.id, menus: this.allKeys }
      this.detailInfo.menus = this.allKeys
      await editRole(this.detailInfo).then(() => {
        this.$message.success('授权成功')
        this.drawer = false
        this.getRoleList()
      })
    },
    goAdd() {
      this.$router.push({ name: 'RoleDetail' })
    },
    goEdit(id) {
      this.$router.push({ name: 'RoleDetail', params: { id: id || undefined } })
    },
    async goRemove(id) {
      this.$confirm('此操作将永久删除该角色, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          removeRole({ id }).then(() => {
            this.$message.success('删除成功')
            this.getRoleList()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    async showMenu(id) {
      this.grantMenuObj.menu_ids = []
      this.apiQueryMenuTree()
      this.drawer = true
      this.grantMenuObj.id = id
      // const { menus } = await getRoleDetail({ id })
      this.detailInfo = await getRoleDetail({ id })
      // this.grantMenuObj.menu_ids = this.fileterMenuIds(this.detailInfo.menus).filter(item => !this.$refs.tree.getNode(item).childNodes.length)
      this.grantMenuObj.menu_ids = this.detailInfo.menus_ids.filter((item) => !this.$refs.tree.getNode(item).childNodes.length)
    },
    fileterMenuIds(list) {
      let menuIds = []
      list.forEach((item) => {
        if (item.children && item.children.length > 0) {
          menuIds = this.fileterMenuIds(item.children)
        } else {
          menuIds.push(item.id)
        }
      })
      return menuIds
    },
    handleClick() {
      this.getRoleList()
    },
    reset() {
      Object.assign(this._data.searchData, this.$options.data().searchData)
      this.getRoleList()
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  min-width: 800px;
  .search-header /deep/ {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .arrow {
      text-align: right;
      .open {
        color: #409eff;
        font-size: 16px;
        margin: 0 10px;
        cursor: pointer;
      }
      .updown {
        color: #409eff;
        font-size: 16px;
        transition: all 0.5s;
      }
      .go {
        transform: rotate(-180deg);
      }
    }
    .el-form-item {
      width: 20%;
      min-width: 300px;
      margin-bottom: 0;
      margin-right: 0;
    }
  }
  .el-divider {
    margin: 20px 0;
  }
  .btn-group {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
}
</style>
